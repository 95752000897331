@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

html, .ant-layout {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
}

.ant-layout-sider-children {
  overflow: auto;
}

.ant-menu-root {
  height: 100%;
}

path.js-line {
  filter: drop-shadow(0 0 1px #fff);
}

.zone-button.ant-btn-default:not(:hover){
  border-color: transparent;
  box-shadow: none;
}

.visibility-on-parent-hover {
  visibility: hidden;
}

.parent-hover:hover .visibility-on-parent-hover {
  visibility: visible;
}

